export type LocalStorageKeys =
  | "accessData"
  | "bossMode"
  | "lastPropertiesView"
  | "savedFiltersPortfolio"
  | "savedFiltersSubportfolio";

/**
 * Module wrapping localStorage methods so that we can store non-primitive values, but also so that
 * we can store per user, which will help when spoofing.
 */
export default {
  get(key: LocalStorageKeys, userId?: string | number, orgId?: string): any {
    return decode(window.localStorage.getItem(generateKey(key, userId, orgId)));
  },

  set(key: LocalStorageKeys, val: any, userId?: string | number, orgId?: string): void {
    window.localStorage.setItem(generateKey(key, userId, orgId), JSON.stringify(val));
  },

  remove(key: LocalStorageKeys, userId?: string | number, orgId?: string): void {
    window.localStorage.removeItem(generateKey(key, userId, orgId));
  },
};

function decode(value: string | null): string {
  let parsedValue = value ?? "";

  try {
    parsedValue = JSON.parse(parsedValue);
  } catch (err) {
    parsedValue = "";
  }

  return parsedValue;
}

export function generateKey(key: string, userId: string | number = "", orgId: string = "") {
  return `${key}${userId ? `_${userId}` : ""}${orgId ? `_${orgId}` : ""}`;
}
