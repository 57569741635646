import { getNewAccessToken, isInBossMode, isSpoofing } from "@/js/utils/authentication";

let refreshPromise: Promise<void> = null;

// we need to wrap the global fetch function so that we can have a global 401 handler. not all our
// fetch requests will go through resourcerer, so the wrapping needs to be at the lower fetch level.
if (typeof window !== "undefined") {
  const globalFetch = window.fetch;

  window.fetch = (path, options = {}) => {
    /**
     * This is one of our spoof guards, and it will be used for those requests that don't use
     * resourcerer, for example, those that use ApiService directly. There will be another one
     * that resourcerer will use instead to provide better CRUD spoofing UX.
     */
    // @ts-ignore - spoofBypass will exist on options if we pass it
    if (options.method && options.method !== "GET" && isSpoofing() && !options.spoofBypass) {
      if (!isInBossMode()) {
        return Promise.resolve(new Response((options.body as string) || "{}", { status: 200 }));
      } else if (
        !window.confirm(
          // @ts-ignore - params will exist on options
          `Confirm: ${options.method} at ${path} with ${JSON.stringify(options.params, null, 2)}`,
        )
      ) {
        return Promise.reject({});
      }
    }

    // TODO: CI is erroring on a undici type error, but we shouldn't be using undici anywhere
    // for fetching
    // @ts-ignore
    return globalFetch(path, options).then((res) => {
      // when we 401, we put a hold on resolving our request until we try getting a new access
      // token. when that succeeds, we re-run the request. because promises can get multiple
      // callbacks attached, we can do this for each request that takes place while the refresh
      // request is in flight, and then all paused requests will get re-run automatically.
      // if getNewAccessToken rejects, the whole fetch request rejects (and the user gets sent to
      // the signin page)
      if (res.status === 401 && window.location.pathname !== "/signin") {
        if (!refreshPromise) {
          refreshPromise = getNewAccessToken(globalFetch).then(() => {
            // make sure to reset this after the refresh request resolves so that this conditional
            // block will get triggered again
            refreshPromise = null;
          });
        }

        // attach a callback to replay the request when the refresh request resolves
        // @ts-ignore
        return refreshPromise.then(() => globalFetch(path, options));
      }

      return res;
    });
  };
}
