import { getEffectiveUser, getPortfolioOrg, getRealUser } from "@/js/core/coreResources";
import LocalStorage from "@/js/core/localStorage";
import { MixpanelTracking } from "@/js/services/mixpanel";

type AccessPayload = {
  user_id: string;
};

export async function submitTextEnableCode(code: string) {
  await fetch("/api/confirm-sms/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code }),
  })
    .then(async (res) => {})
    .catch((rejected) => {});

  return true;
}

export async function requestMFACode() {}

export async function enableTextMFA() {
  await fetch("/api/auth/sms_twilio/activate/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      await res.json();
    })
    .catch((rejected) => {});
}

export async function disableTextMFA(code: string) {
  await fetch("/api/auth/activate/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code }),
  })
    .then(async (res) => {
      await res.json();
    })
    .catch((rejected) => {});
}

export async function activateOtp() {
  let result: string | null = null;

  await fetch("/api/auth/app/activate/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let json = await res.json();

      if (res.status === 200) {
        result = json.details;
      }
    })
    .catch((rejected) => {});

  return result;
}

export async function deactivateOtp(otpCode: string) {
  let result = false;
  const body = JSON.stringify({ code: otpCode });

  await fetch("/api/auth/app/deactivate/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  })
    .then(async (res) => {
      if (res.status === 200) {
        result = true;
      }
    })
    .catch((rejected) => {});

  return result;
}

export async function confirmActivateOtp(otpCode: string) {
  let successful = false;
  const body = JSON.stringify({ code: otpCode });

  await fetch("/api/auth/app/activate/confirm/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  })
    .then(async (res) => {
      if (res.status === 200) {
        successful = true;
      }
    })
    .catch((rejected) => {});

  return successful;
}

export async function confirmOtpLogin(otpCode: string, ephemeralToken: string) {
  const response = await fetch("/api/confirm-otp", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      code: otpCode,
      ephemeral_token: ephemeralToken,
    }),
  });
  const json = await response.json();

  if (response.ok) {
    setAccessData(json.success?.access);
  }

  return response.ok;
}

export async function activeMethods() {
  let result: any = false;

  await fetch("/api/auth/mfa/user-active-methods/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      if (res.status === 200) {
        result = await res.json();
      }
    })
    .catch((rejected) => {});

  return result;
}

export async function login(username: string, password: string) {
  const response = await fetch("/api/login", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });

  const json = await response.json();

  if (response.ok && !json.ephemeral_token) {
    setAccessData(json.success?.access);
  }

  return { json, status: response.status };
}

export async function logout() {
  removeAccessData();

  return fetch("/api/signout", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  }).then(() => {
    MixpanelTracking.getInstance().loggedOut();
    window.location.assign("/signin");
  });
}

/**
 * Decodes the middle piece of the access token and sets that data as localStorage so that we can
 * use it for things like our core resources.
 */
export function setAccessData(token = "") {
  let decoded;

  try {
    // base64 decode, then JSON parse
    decoded = JSON.parse(window.atob(token.split(".")[1])) as AccessPayload;
  } catch (err) {
    decoded = {};
  }

  if (decoded.user_id) {
    LocalStorage.set("accessData", { userId: decoded.user_id });
  }
}

export function removeAccessData() {
  LocalStorage.remove("accessData");
}

export const isLoggedIn = () => !!LocalStorage.get("accessData");

// theoretically you can never be is_spoofing if you're not is_staff, but it's just a sanity check
export const isSpoofing = () =>
  !!(getRealUser() && getRealUser().get("is_staff") && getRealUser().get("is_spoofing"));

// the isSpoofing doesn't really matter, but it's just a sanity check
export const isInBossMode = () =>
  isSpoofing() &&
  !!LocalStorage.get(
    "bossMode",
    getEffectiveUser()?.get("token"),
    getPortfolioOrg()?.organization__token,
  );

/**
 * Uses our refresh token to call our refresh endpoint, which sets a new access cookie. If this
 * fails for any reason (401 or other), remove access data and redirect to signin.
 *
 * Note: we pass in fetch here because we want to make sure it's not our global fetch wrapper but
 * native fetch.
 */
export const getNewAccessToken = (
  fetch: (path: string, options: { method: string }) => Promise<Response>,
) =>
  fetch("/api/refresh", { method: "POST" }).then((res) => {
    if (!res.ok) {
      const returnPath = encodeURIComponent(window.location.pathname + window.location.search);

      removeAccessData();
      window.location.assign(`/signin?returnTo=${returnPath}`);

      return Promise.reject();
    }
  });
