import type { ReactNode } from "react";

import { Tooltip } from "antd";
import { useRef, useState } from "react";

/**
 * A component that shows an ellipsis if the text node overflows its container; this happens in CSS.
 * It also shows a tooltip with the full text when hovered over; this happens in JS in this
 * component.
 *
 * TODO: support multi-line overflow
 */
export default function TextOverflow({ children }: { children: ReactNode }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const overflowRef = useRef<HTMLSpanElement>();

  const onMouseEnter = () => {
    if (overflowRef.current.offsetWidth < overflowRef.current.scrollWidth) {
      setShowTooltip(true);
    }
  };

  const onMouseLeave = () => {
    if (setShowTooltip) {
      setShowTooltip(false);
    }
  };

  return (
    <span
      ref={overflowRef}
      className="TextOverflow"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {showTooltip ?
        <Tooltip title={children}>{children}</Tooltip>
      : children}
    </span>
  );
}
