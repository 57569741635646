import { Model } from "resourcerer";

import { type FeatureFlags } from "@/js/models/types";

export class CoreFeatureFlagsModel extends Model<FeatureFlags> {
  parse(flags: { feature_configurations: FeatureFlags }) {
    return flags.feature_configurations;
  }

  url() {
    return "/api/feature_configuration/configs";
  }
}

export default class FeatureFlagsModel extends CoreFeatureFlagsModel {
  static dependencies = ["organization_token", "property_token"];
}
