import { useEffect } from "react";

import Button from "@/components/Button";
import Spinner from "@/components/Spinner";

import { getPortfolioOrg } from "@/js/core/coreResources";
import useLocalStorage from "@/js/hooks/useLocalStorage";
import useNotificationContext from "@/js/hooks/useNotificationContext";
import useSaveReducer from "@/js/hooks/useSaveReducer";
import { UnspoofModel } from "@/js/models/SpoofModel";
import { isSpoofing } from "@/js/utils/authentication";

/**
 * Banner for the top of the app while in spoofing mode to make clear that we're in spoof mode.
 * Also has a button to unspoof and move in and out of boss mode (which is simple localStorage
 * flag).
 */
export default function SpoofBanner() {
  const [{ isSaving }, saveDispatch] = useSaveReducer();
  const { notify } = useNotificationContext();
  const [isInBossMode, setBossMode, removeBossMode] = useLocalStorage("bossMode");

  const onUnspoof = () => {
    saveDispatch({ type: "saving" });

    new UnspoofModel()
      .save()
      .then(() => window.location.assign("/"))
      .catch(() => {
        saveDispatch({ type: "reset" });
        notify("Could not end spoof session");
      });
  };

  /**
   * When we enter boss mode, add a class to the body so we can style explicitly and outlandishly.
   * Also add an unload handler so that when the user closes the tab they don't stay in boss mode.
   */
  useEffect(() => {
    if (isInBossMode) {
      const onBeforeUnload = () => removeBossMode({ silent: true });

      document.body.classList.add("boss-mode");
      window.addEventListener("beforeunload", onBeforeUnload);

      return () => {
        document.body.classList.remove("boss-mode");
        window.removeEventListener("beforeunload", onBeforeUnload);
      };
    }
  }, [isInBossMode]);

  return isSpoofing() ?
      <div className="SpoofBanner">
        <div>
          <h2>CURRENTLY SPOOFING: {getPortfolioOrg().organization__name}</h2>
          <span>
            <small>ID: {getPortfolioOrg().organization__token}</small>
          </span>
        </div>
        <div>
          <Button
            onClick={async () => {
              const search = new URLSearchParams(window.location.search);

              search.set("o", getPortfolioOrg().organization__token);

              await navigator.clipboard.writeText(
                `${window.location.href.split("?")[0]}?${search.toString()}`,
              );
            }}
          >
            Copy spoofing link
          </Button>
          <Button
            flavor="primary"
            onClick={() => (!isInBossMode ? setBossMode(true) : removeBossMode())}
          >
            {isInBossMode ? "Exit" : "Enter"} Boss Mode
          </Button>
          <Button disabled={isSaving} flavor="primary" onClick={onUnspoof}>
            {isSaving ?
              <Spinner flavor="inline" />
            : null}
            Unspoof
          </Button>
        </div>
      </div>
    : null;
}
