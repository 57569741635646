import { ReactNode, useEffect, useRef } from "react";

import IconButton from "@/components/Button/IconButton";

import useModalContext from "@/js/hooks/useModalContext";
import { classnames } from "@/js/utils/cambio";

import { PortalContext } from "../Portal";

/**
 * Generic Modal component to launch with the ModalSequenceManager.
 */
export default function Modal({
  children,
  className = "",
  /** Hero image for the modal - goes above the title */
  hero,
  /** Optional title for the modal */
  title,
  /** Optional subtitle for the modal */
  subtitle,
  footer,
  /**
   * Tells the Modal that there should be an x-to-close button and that it should close when
   * clicking outside the modal or hitting the escape key.
   */
  withCloseShortcuts = true,
  size = "medium",
}: {
  children: ReactNode;
  className?: string;
  hero?: string;
  title?: ReactNode;
  subtitle?: ReactNode;
  footer?: ReactNode;
  withCloseShortcuts?: boolean;
  size?: "small" | "medium" | "large" | "x-large";
}) {
  const { msmRef, end } = useModalContext();
  const modalRef = useRef<HTMLDialogElement>();

  /**
   * Handlers for ending the modal sequence when the user clicks outside the mdoal or hits the
   * escape key.
   */
  useEffect(() => {
    if (withCloseShortcuts) {
      const onClickOutside = (evt: MouseEvent) => {
        if (evt.target === msmRef.current.firstChild) {
          end();
        }
      };

      const onKeyDown = (evt: KeyboardEvent) => {
        if (evt.key === "Escape") {
          end();
        }
      };

      document.addEventListener("keydown", onKeyDown);
      document.addEventListener("click", onClickOutside);

      return () => {
        document.removeEventListener("click", onClickOutside);
        document.removeEventListener("keydown", onKeyDown);
      };
    }
  }, []);

  return (
    <PortalContext.Provider value={{ parent: modalRef }}>
      <dialog
        ref={modalRef}
        className={classnames("Modal", { [size]: size !== "medium" }, className)}
        open
      >
        {hero ?
          <img className="modal-hero-img" src={hero} />
        : null}
        {title ?
          <h1>{title}</h1>
        : null}
        {subtitle ?
          <p>{subtitle}</p>
        : null}
        {children}
        {withCloseShortcuts && <IconButton label="Close" icon="x" onClick={end} />}
        {footer ?
          <footer>{footer}</footer>
        : null}
      </dialog>
    </PortalContext.Provider>
  );
}
