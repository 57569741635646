import { type FunctionComponent } from "react";

import AlarmWarning from "@/images/icons/alarm-warning.svg";
import ArrowBack from "@/images/icons/arrow-back.svg";
import Bank from "@/images/icons/bank.svg";
import Benchmark from "@/images/icons/benchmark.svg";
import BookOpenText from "@/images/icons/book-open-text.svg";
import Briefcase from "@/images/icons/briefcase.svg";
import Building from "@/images/icons/building.svg";
import Buildings from "@/images/icons/buildings.svg";
import Calendar from "@/images/icons/calendar.svg";
import Campus from "@/images/icons/campus.svg";
import Car from "@/images/icons/car.svg";
import CaretUp from "@/images/icons/caret-up.svg";
import ChartLineDown from "@/images/icons/chart-line-down.svg";
import Check from "@/images/icons/check.svg";
import ChevronLeft from "@/images/icons/chevron-left.svg";
import ChevronUpDown from "@/images/icons/chevron-up-down.svg";
import Church from "@/images/icons/church.svg";
import Clipboard from "@/images/icons/clipboard.svg";
import ClockCountdown from "@/images/icons/clock-countdown.svg";
import Cloud from "@/images/icons/cloud.svg";
import Coin from "@/images/icons/coin.svg";
import Controls from "@/images/icons/controls.svg";
import Copy from "@/images/icons/copy.svg";
import Dining from "@/images/icons/dining.svg";
import Dollar from "@/images/icons/dollar.svg";
import Download from "@/images/icons/download.svg";
import Drop from "@/images/icons/drop.svg";
import Earthquake from "@/images/icons/earthquake.svg";
import Education from "@/images/icons/education.svg";
import Entertainment from "@/images/icons/entertainment.svg";
import ExclamationCircle from "@/images/icons/exclamation-circle.svg";
import Files from "@/images/icons/files.svg";
import Filter from "@/images/icons/filter.svg";
import Flood from "@/images/icons/flood.svg";
import FullScreenExit from "@/images/icons/full-screen-exit.svg";
import FullScreen from "@/images/icons/full-screen.svg";
import Fund from "@/images/icons/fund.svg";
import Globe from "@/images/icons/globe.svg";
import Grid from "@/images/icons/grid.svg";
import Healthcare from "@/images/icons/healthcare.svg";
import Heat from "@/images/icons/heat.svg";
import Heatwave from "@/images/icons/heatwave.svg";
import HelpCircle from "@/images/icons/help-circle.svg";
import Home from "@/images/icons/home.svg";
import Hospitality from "@/images/icons/hospitality.svg";
import Hurricane from "@/images/icons/hurricane.svg";
import InfoCircle from "@/images/icons/info-circle.svg";
import Kebab from "@/images/icons/kebab.svg";
import Leaf from "@/images/icons/leaf.svg";
import LightBulb from "@/images/icons/lightbulb.svg";
import Lightning from "@/images/icons/lightning.svg";
import List from "@/images/icons/list.svg";
import Lock from "@/images/icons/lock.svg";
import Manufacturing from "@/images/icons/manufacturing.svg";
import MixedUse from "@/images/icons/mixed-use.svg";
import Nodes from "@/images/icons/nodes.svg";
import Note from "@/images/icons/note.svg";
import Open from "@/images/icons/open.svg";
import Pdf from "@/images/icons/pdf.svg";
import Pencil from "@/images/icons/pencil.svg";
import Pin from "@/images/icons/pin.svg";
import PlugConnected from "@/images/icons/plug_connected.svg";
import Plus from "@/images/icons/plus.svg";
import PresentationChart from "@/images/icons/presentation-chart.svg";
import Retail from "@/images/icons/retail.svg";
import Scroll from "@/images/icons/scroll.svg";
import Search from "@/images/icons/search.svg";
import ShieldLock from "@/images/icons/shield-lock.svg";
import SidePanel from "@/images/icons/side-panel.svg";
import Snowflake from "@/images/icons/snowflake.svg";
import Solar from "@/images/icons/solar.svg";
import Tech from "@/images/icons/tech.svg";
import Trash from "@/images/icons/trash.svg";
import TrendUp from "@/images/icons/trend-up.svg";
import UserCircle from "@/images/icons/user-circle.svg";
import User from "@/images/icons/user.svg";
import UsersThree from "@/images/icons/users-three.svg";
import Utility from "@/images/icons/utility.svg";
import Wall from "@/images/icons/wall.svg";
import Warehouse from "@/images/icons/warehouse.svg";
import Wildfire from "@/images/icons/wildfire.svg";
import X from "@/images/icons/x.svg";

export const Icons = {
  "alarm-warning": AlarmWarning,
  "arrow-back": ArrowBack,
  "arrow-down": ArrowBack,
  "arrow-forward": ArrowBack,
  "arrow-up": ArrowBack,
  bank: Bank,
  benchmark: Benchmark,
  "book-open-text": BookOpenText,
  briefcase: Briefcase,
  building: Building,
  buildings: Buildings,
  calendar: Calendar,
  campus: Campus,
  car: Car,
  "caret-down": CaretUp,
  "caret-left": CaretUp,
  "caret-right": CaretUp,
  "caret-up": CaretUp,
  "chart-line-down": ChartLineDown,
  check: Check,
  "chevron-down": ChevronLeft,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronLeft,
  "chevron-up": ChevronLeft,
  "chevron-up-down": ChevronUpDown,
  church: Church,
  clipboard: Clipboard,
  "clock-countdown": ClockCountdown,
  cloud: Cloud,
  coin: Coin,
  copy: Copy,
  controls: Controls,
  dining: Dining,
  dollar: Dollar,
  download: Download,
  drop: Drop,
  earthquake: Earthquake,
  education: Education,
  entertainment: Entertainment,
  "exclamation-circle": ExclamationCircle,
  files: Files,
  filter: Filter,
  flood: Flood,
  "full-screen": FullScreen,
  "full-screen-exit": FullScreenExit,
  fund: Fund,
  globe: Globe,
  grid: Grid,
  healthcare: Healthcare,
  heat: Heat,
  heatwave: Heatwave,
  "help-circle": HelpCircle,
  home: Home,
  hospitality: Hospitality,
  hurricane: Hurricane,
  "info-circle": InfoCircle,
  kebab: Kebab,
  leaf: Leaf,
  lightning: Lightning,
  "light-bulb": LightBulb,
  list: List,
  lock: Lock,
  manufacturing: Manufacturing,
  "mixed-use": MixedUse,
  nodes: Nodes,
  note: Note,
  open: Open,
  pdf: Pdf,
  pencil: Pencil,
  pin: Pin,
  "plug-connected": PlugConnected,
  plus: Plus,
  "presentation-chart": PresentationChart,
  retail: Retail,
  scroll: Scroll,
  search: Search,
  "shield-lock": ShieldLock,
  "side-panel": SidePanel,
  snowflake: Snowflake,
  solar: Solar,
  tech: Tech,
  trash: Trash,
  "trend-up": TrendUp,
  "user-circle": UserCircle,
  user: User,
  "users-three": UsersThree,
  utility: Utility,
  wall: Wall,
  warehouse: Warehouse,
  wildfire: Wildfire,
  x: X,
};

type Icons = keyof typeof Icons;

export type IconsType = Icons;

interface SvgIconProps {
  name: Icons;
  className?: string;
}

export default function SvgIcon({ name, className = "" }: SvgIconProps) {
  const Icon = Icons[name] as FunctionComponent<{ className: string }>;

  return <Icon className={`SvgIcon icon-${name} ${className}`} />;
}
