/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `USD` - Usd
 * * `CAD` - Cad
 */
export enum NaturalGasPriceCurrencyEnum {
    USD = 'USD',
    CAD = 'CAD',
}
