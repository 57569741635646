/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Building } from '../models/Building';
import type { BuildingProperties } from '../models/BuildingProperties';
import type { PatchedBuilding } from '../models/PatchedBuilding';
import type { PatchedBuildingProperties } from '../models/PatchedBuildingProperties';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BldgSciService {

    /**
     * Note: this is a core fact table. No update is allowed (delete only).
     * @returns Building
     * @throws ApiError
     */
    public static bldgSciBuildingsList(): CancelablePromise<Array<Building>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/',
        });
    }

    /**
     * Note: this is a core fact table. No update is allowed (delete only).
     * @returns Building
     * @throws ApiError
     */
    public static bldgSciBuildingsCreate({
        requestBody,
    }: {
        requestBody: Building,
    }): CancelablePromise<Building> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/bldg_sci/buildings/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BuildingProperties
     * @throws ApiError
     */
    public static bldgSciBuildingsPropertiesList({
        buildingToken,
    }: {
        buildingToken: string,
    }): CancelablePromise<Array<BuildingProperties>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{building_token}/properties/',
            path: {
                'building_token': buildingToken,
            },
        });
    }

    /**
     * @returns BuildingProperties
     * @throws ApiError
     */
    public static bldgSciBuildingsPropertiesCreate({
        buildingToken,
        requestBody,
    }: {
        buildingToken: string,
        requestBody: BuildingProperties,
    }): CancelablePromise<BuildingProperties> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/bldg_sci/buildings/{building_token}/properties/',
            path: {
                'building_token': buildingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BuildingProperties
     * @throws ApiError
     */
    public static bldgSciBuildingsPropertiesPartialUpdate({
        buildingToken,
        requestBody,
    }: {
        buildingToken: string,
        requestBody?: PatchedBuildingProperties,
    }): CancelablePromise<BuildingProperties> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/bldg_sci/buildings/{building_token}/properties/',
            path: {
                'building_token': buildingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static bldgSciBuildingsPropertiesDestroy({
        buildingToken,
    }: {
        buildingToken: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/bldg_sci/buildings/{building_token}/properties/',
            path: {
                'building_token': buildingToken,
            },
        });
    }

    /**
     * Note: this is a core fact table. No update is allowed (delete only).
     * @returns Building
     * @throws ApiError
     */
    public static bldgSciBuildingsRetrieve({
        token,
    }: {
        token: string,
    }): CancelablePromise<Building> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{token}/',
            path: {
                'token': token,
            },
        });
    }

    /**
     * Note: this is a core fact table. No update is allowed (delete only).
     * @returns Building
     * @throws ApiError
     */
    public static bldgSciBuildingsUpdate({
        token,
        requestBody,
    }: {
        token: string,
        requestBody: Building,
    }): CancelablePromise<Building> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/bldg_sci/buildings/{token}/',
            path: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Note: this is a core fact table. No update is allowed (delete only).
     * @returns Building
     * @throws ApiError
     */
    public static bldgSciBuildingsPartialUpdate({
        token,
        requestBody,
    }: {
        token: string,
        requestBody?: PatchedBuilding,
    }): CancelablePromise<Building> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/bldg_sci/buildings/{token}/',
            path: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Note: this is a core fact table. No update is allowed (delete only).
     * @returns void
     * @throws ApiError
     */
    public static bldgSciBuildingsDestroy({
        token,
    }: {
        token: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/bldg_sci/buildings/{token}/',
            path: {
                'token': token,
            },
        });
    }

}
