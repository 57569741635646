// Context incorrect onboarding links were sent out, this is a list of onboarding tokens which should be overridden to be in
export const temporaryOnboardingLinkOverrides = [
  // Pembroke Place
  "ENvSa4QgHCYTPcMvhWVjOz2nSSYhdxdGq4KD_qNgJwmRxeXQIfm7g2Mp3SdYak8Y",
  // Aberdeen Square
  "ggAhklAQ5JGl5AEIK_7x2ZzRJtXftd_VNvWf-MvLm66GbkBb4sPI-CqfDw5Z6zwU",
  // Naranja Lakes
  "rUVE1XJ3qcxbfn5uEjBicjD7kiY4En5-bharn9PR1_E8zWEaFb0tK5FBLbESv5cD",
  // Plaza de Oro
  "_zM2mJ--aYlV-Ih-I1eIKPSWGS2IB21nQ_fYpTIpNZ-6WDu2jYhByZ7ZYpvLm3hz",
  // Chickasaw Trail
  "tAH_DnPFrBycFOGAVUlWOzmOvfsfbknurxnbyU9X0MANnETzAEsswO6DkVGAy6KI",
  // El Paraiso
  "gAeYWhyQ7LhRwSSvlHAUzYJ3OsrjBKrPJFr1J7dL8S0s0YHMfiYjPzrG1LjZnhPO",
  // Crossroads Square
  "5Czg3LoP22MZNAsgzpQziOs6xe4q-NTLW73cMfpF3PRa4QeyoIgzOxRvmSVsRsyO",
];

export enum SystemOverrideTokens {
  DEMO_SPACE_TOKEN_MADISON = "s_3KnBTRhgMnHLZVWuCdSg3f",
  OXFORD_BACKBAY_TOKEN = "s_eMMmQNYUfw98MJK7BWQEtX",
  OXFORD_FRANKLIN_TOKEN = "s_hdZfKA5QEn4wmi2uXSB6ry",
  OXFORD_NYA_TOKEN = "s_32o4V6aiL68ZdHyvHeaqMs",
  OXFORD_900_16th_TOKEN = "s_ygkoFYDQHHqPf8degYNgWw",
  NUVEEN_EAST_SEGO = "s_KCixRxPFKjMF2ksYqHWrih",
  NUVEEN_DEFOOR = "s_evbZce9siz9gJexWX9cpcB",
  NUVEEN_KIRKLAND = "s_36KQC29SZFsKZJv8GtUkgK",
  CROWN_UNIVERSITY_AVENUE = "s_RXAaFq2iaQfi5h8HBoySf6",
  CROWN_SHEPPARD = "s_42Cxz2tNj74Tsbn8YhuUbs",
  CROWN_KING_WEST = "s_3dR3eVNkqrhRDHZ2d26CyF",
  CARDINAL_COPPER_SOCIAL_TOKEN = "s_Y2ZfNSiq6WH4Gk8jYD3bkB",
  CARDINAL_FIFTY_TWENTY_FIVE = "s_6wddSa9FhVRashQSDDi9Y7",
  PRINCIPAL_SOLARIS_KEY = "s_3eLsCH5yfGn9wHzDfYCsCA",
  PRINCIPAL_CODA = "s_433dfUjjGHxfC5HsHfd9FH",
  PRINCIPAL_TECH_DISTRIBUTION_CENTER = "s_35i8fQQ7gkNvwmjMfmNeW8",
  PRINCIPAL_EDENRED = "s_YSgdsz6XgD8gxyYeQtvj7D",
  PRINCIPAL_STEIGEREILAND = "s_3chi8jUWZaLnpADLiXPyG6",
  CARMEL_TOWERS_AT_RINCON = "s_Kpx7Li2ZsZtNTRK8T2tMhN",
  BEACON_TABOR_CENTRE = "s_Nbr7ygfgtUfjErQVyykksA",
  BEACON_LAFAYETTE = "s_Qg2CVXfpn8MKU47pSxGuva",
  BEACON_PENTAGON_I = "s_4gPCTtBbnGDjssLX5eHhq6",
  BEACON_PENTAGON_II = "s_36rKKSWNP6K5WzT2izTfN6",
  BEACON_TERRELL_PLACE = "s_NvQXpm5f8Yv59GQyH64q9g",
  BSCP7_SOUTH_LASALLE = "s_3QZQ4bW3hBk6cotzXhN2Ca",
  BSCP7_NORTH_STATE = "s_yaH4P3z5bAGho2DYMXDoy6",
  BSCP7_PACIFIC_CONCOURSE_5220 = "s_boDQAPiPzDNqDndEtQtk2h",
  BSCP7_PACIFIC_CONCOURSE_5230 = "s_xZFD7hnMN2LLPTorMAdmUX",
  BSCP8_BROADWAY = "s_3Sm3FsjrGbfVtj28SRixjU",
  STATE_JV_STATE_STREET_53 = "s_dLbqgSozyyaFdXNwFhjozj",
  AEI_BROOKFIELD_TURTLE_COVE = "s_32VbEHj2sYxYP7iMify7xk",
  TMO_CLARY_CROSSING_SENIOR = "s_QcbSpyCdQ3asTtW55Psy4T",
  TMO_LASALLE_APARTMENTS = "s_CjSfx9ejSPishaaYLQyiYw",
  TMO_CLARY_CROSSING_APARTMENTS = "s_3FYkjbUknsGh5cXX4UX33N",
  BEACON_BCAL_650_TOWNSEND = "s_3n4o45WLW2GUBiQFEeePJB",
  BEACON_BCAL_BANK_OF_AMERICA_TOWER = "s_P8c9BkMUbWKyuLerNnzbbc",
  BEACON_BCAL_BLANCHARD_PLAZA = "s_3dLeHCwzE2bVYrehZJNshw",
  BEACON_BCAL_GATEWAY_TOWER_5 = "s_3MRKMjJnfCnzvdJ5b3mbqg",
  BEACON_BCAL_METRO_PARK_2 = "s_iMv2PnPjhGLqVvWAGWUftt",
  BEACON_BCAL_METRO_PARK_4 = "s_xn3uV5Q4PLbK2H7W5ctPww",
  BEACON_BCAL_METRO_PARK_7 = "s_o8qosj3GZgCKUn5b3sTDyo",
  BEACON_BCAL_METRO_PARK_8 = "s_nKGyMLufg95Uyr9M8m5QLR",
  BEACON_BCAL_101_NORTH_BRAND = "s_g5bLWuwHQczfpxFoedqSpP",
  BEACON_BCAL_METROPOLITAN_PARK_EAST = "s_ktEM7APzxRG46FyhTBW3uZ",
  BEACON_BCSP_7_ONE_NORTH_DEARBORN = "s_3WwsHutGJp96qjRzNxqLbx",
  BEACON_BCSP_7_AMA_PLAZA = "s_3JpStRBYhGnzrrbHQsoeiX",
  BEACON_BCSP_7_1055_E_COLORADO = "s_3okeGVFyZ9GS7aPR44v7qW",
  BEACON_BCSP_7_575_5TH_AVE = "s_CCSgwiXUAjTWXs4sAy6eDM",
  BEACON_BCSP_8_PEARL_1 = "s_qvPBZbcEN7nE5sApDoQVQ9",
  BEACON_BCSP_8_PEARL_2 = "s_GXJxUWw46kbhaWoNwC8XkH",
  BEACON_BCSP_8_PEARL_3 = "s_3dewNrasdmCbZVFTnWomro",
  BEACON_BCSP_8_PEARL_4 = "s_3rQCnoHmhRGRbvD74zcb3E",
  BEACON_BCSP_8_PEARL_5 = "s_X36QD5xiYfeyyagUTX9zL6",
  BEACON_BCSP_8_PEARL_6 = "s_KH9aFhpH44b8snxKt48Si3",
  BEACON_BCSP_8_PEARL_7 = "s_3wekotU6cyN3ESQbhqktdR",
  BEACON_BCSP_8_PEARL_8 = "s_JwcKZPHQFNHVzY8F7krsST",
  BEACON_BCSP_8_PEARL_10 = "s_3BwEr5p9heYadkYHfJzL7H",
  BEACON_BCSP_8_PEARL_11 = "s_z5DkqDkR5uDCKinZTjeKVq",
  BEACON_BCSP_8_WELLESLEY_GATEWAY = "s_3F6kXp8eW67B6mvA67Fo74",
  BEACON_BCSP_8_1800_LARIMER = "s_3ktxsvrZzpaE5hLmYoGmys",
  BEACON_BCSP_8_600_CONGRESS = "s_fwH9fZhLnwu3UsuunUZ7ur",
  BEACON_BCSP_8_800_NORTH_BRAND = "s_D7KnaW7TqJY9YTzSd2R8ps",
  BEACON_BCSP_8_FOUNDRY_I = "s_5LdsZU9u3KXCXW6Tfhskce",
  BEACON_BCSP_8_FOUNDRY_II = "s_3dM8LDBReWnMbEEmWqG97v",
  BEACON_BCSP_8_WELLESLEY_HILLS_II = "s_fUt8x8LZkJgvaD57Rm7nrr",
  BEACON_BCSP_8_WELLESLEY_HILLS_IV = "s_3gMbotKx2qzGejFGZv3fBT",
  BEACON_BCSP_9_ONE_BRATTLE_SQUARE = "s_Uiv6Mj9WxFJPfoLTmiR42L",
  INFRASTRUCTURE_ONTARIO_GREENVILLE = "s_3uGTtypZcXs7UpsHRXo9ks",
  INFRASTRUCTURE_ONTARIO_MAPLEHURST = "s_8LUjgYdK5BMSym3okDBGa4",
  INFRASTRUCTURE_ONTARIO_GOVERNMENT = "s_AD8WwjufpVhrtKztYAANcE",
  OXFORD_PASUBIO_CENTRALE = "s_8JeqetB5XvHfyijiqRiW8y",
  OXFORD_ONE_BOSTON_PLACE = "s_hkAzvCG6mCwxbte3YcCPiC",
  OXFORD_KARPERSTRAAT_HQ = "s_eWRg4z4QKtC8C2euyFA7sH",
  OXFORD_SUNNYVALE_APARTMENTS_NORTH = "s_3nDwtP3NQau4Gmb7oXFukv",
  OXFORD_SUNNYVALE_APARTMENTS_SOUTH = "s_WKd7P6dEkZ9F98Uwu7zdoF",
  OXFORD_NEW_YORK_AVE = "s_f6omckvqS3KMbnA549RhK2",
}

// We have turned on binning and ranking feature for these properties, so the
//   hardcoded values are no longer needed for these properties, we have deleted the values,
//   Ideally we would remove all hardcoded SystemOverrideTokens as well,
//   but some of them are still referenced somewhere in the code, so we are keeping them
//   but need return false to avoid using the removed hardcoded values.
export const removedHardcodedSpaces = [
  SystemOverrideTokens.CARDINAL_COPPER_SOCIAL_TOKEN,
  SystemOverrideTokens.CARDINAL_FIFTY_TWENTY_FIVE,
];

export function isHardcodedSystemSpace(spaceToken: string): boolean {
  const enumValues: string[] = Object.values(SystemOverrideTokens);

  if (removedHardcodedSpaces.includes(spaceToken as SystemOverrideTokens)) {
    return false;
  }

  return enumValues.includes(spaceToken);
}

export function carbonROIRank(system: string, spaceToken: string) {
  if (spaceToken === SystemOverrideTokens.CROWN_UNIVERSITY_AVENUE) {
    return system === "total" ? 1 : null;
  }

  if (spaceToken === SystemOverrideTokens.CROWN_KING_WEST) {
    return system === "total" ? 3 : null;
  }

  if (spaceToken === SystemOverrideTokens.CROWN_SHEPPARD) {
    return system === "total" ? 1 : null;
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_650_TOWNSEND) {
    switch (system) {
      case "total":
        return 4;
      case "controls":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "envelope":
        return 3;
      case "heating":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_BANK_OF_AMERICA_TOWER) {
    switch (system) {
      case "total":
        return 2;
      case "controls":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "envelope":
        return 2;
      case "heating":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_BLANCHARD_PLAZA) {
    switch (system) {
      case "total":
        return 1;
      case "controls":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "envelope":
        return 1;
      case "heating":
        return 1;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_GATEWAY_TOWER_5) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 2;
      case "envelope":
        return 3;
      case "heating":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_METRO_PARK_2) {
    switch (system) {
      case "total":
        return 1;
      case "controls":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "envelope":
        return 1;
      case "heating":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_METRO_PARK_4) {
    switch (system) {
      case "total":
        return 2;
      case "controls":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "envelope":
        return 1;
      case "heating":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_METRO_PARK_7) {
    switch (system) {
      case "total":
        return 2;
      case "controls":
        return 1;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "envelope":
        return 2;
      case "heating":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_METRO_PARK_8) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "envelope":
        return 2;
      case "heating":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_101_NORTH_BRAND) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "envelope":
        return 3;
      case "heating":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_METROPOLITAN_PARK_EAST) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "envelope":
        return 2;
      case "heating":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_7_ONE_NORTH_DEARBORN) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "envelope":
        return 3;
      case "heating":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_7_AMA_PLAZA) {
    switch (system) {
      case "total":
        return 1;
      case "controls":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 2;
      case "envelope":
        return 1;
      case "heating":
        return 3;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_7_1055_E_COLORADO) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 3;
      case "envelope":
        return 3;
      case "heating":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_7_575_5TH_AVE) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "envelope":
        return 3;
      case "heating":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_1) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 3;
      case "envelope":
        return 3;
      case "heating":
        return 3;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_2) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "envelope":
        return 2;
      case "heating":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_3) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 3;
      case "envelope":
        return 2;
      case "heating":
        return 3;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_4) {
    switch (system) {
      case "total":
        return 2;
      case "controls":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "envelope":
        return 2;
      case "heating":
        return 1;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_5) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 4;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "envelope":
        return 2;
      case "heating":
        return 1;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_6) {
    switch (system) {
      case "total":
        return 2;
      case "controls":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "envelope":
        return 2;
      case "heating":
        return 3;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_7) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "envelope":
        return 1;
      case "heating":
        return 3;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_8) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "envelope":
        return 1;
      case "heating":
        return 3;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_10) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "envelope":
        return 1;
      case "heating":
        return 3;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_11) {
    switch (system) {
      case "total":
        return 2;
      case "controls":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "envelope":
        return 1;
      case "heating":
        return 3;
      case "lighting":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_WELLESLEY_GATEWAY) {
    switch (system) {
      case "total":
        return 2;
      case "controls":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "envelope":
        return 1;
      case "heating":
        return 2;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_1800_LARIMER) {
    switch (system) {
      case "total":
        return 1;
      case "controls":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "envelope":
        return 1;
      case "heating":
        return 1;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_600_CONGRESS) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "envelope":
        return 1;
      case "heating":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_800_NORTH_BRAND) {
    switch (system) {
      case "total":
        return 2;
      case "controls":
        return 4;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "envelope":
        return 3;
      case "heating":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_FOUNDRY_I) {
    switch (system) {
      case "total":
        return 2;
      case "controls":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "envelope":
        return 2;
      case "heating":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_FOUNDRY_II) {
    switch (system) {
      case "total":
        return 4;
      case "controls":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "envelope":
        return 4;
      case "heating":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_WELLESLEY_HILLS_II) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "envelope":
        return 3;
      case "heating":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_WELLESLEY_HILLS_IV) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 4;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "envelope":
        return 2;
      case "heating":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_9_ONE_BRATTLE_SQUARE) {
    switch (system) {
      case "total":
        return 3;
      case "controls":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "envelope":
        return 3;
      case "heating":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.DEMO_SPACE_TOKEN_MADISON) {
    switch (system) {
      case "lighting":
        return 1;
      case "heating":
        return 2;
      case "cooling":
        return 3;
      case "envelope":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.PRINCIPAL_CODA) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 3;
      case "controls":
        return 1;
      case "lighting":
        return 4;
      case "envelope":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.PRINCIPAL_EDENRED) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 3;
      case "controls":
        return 3;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.CARMEL_TOWERS_AT_RINCON) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 3;
      case "controls":
        return 4;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.PRINCIPAL_STEIGEREILAND) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 3;
      case "controls":
        return 3;
      case "lighting":
        return 4;
      case "envelope":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.PRINCIPAL_TECH_DISTRIBUTION_CENTER) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 3;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.PRINCIPAL_SOLARIS_KEY) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 2;
      case "controls":
        return 2;
      case "lighting":
        return 4;
      case "envelope":
        return 3;
      default:
        return null;
    }
  }

  // Nuveen

  if (spaceToken === SystemOverrideTokens.NUVEEN_EAST_SEGO) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.NUVEEN_DEFOOR) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "lighting":
        return 2;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.NUVEEN_KIRKLAND) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 2;
      case "controls":
        return 2;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  //Oxford
  if (spaceToken === SystemOverrideTokens.OXFORD_BACKBAY_TOKEN) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "lighting":
        return 4;
      case "envelope":
        return 3;
      default:
        return null;
    }
  }

  //

  if (spaceToken === SystemOverrideTokens.OXFORD_BACKBAY_TOKEN) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_FRANKLIN_TOKEN) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 3;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_NYA_TOKEN) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "lighting":
        return 2;
      case "envelope":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_900_16th_TOKEN) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BEACON_TABOR_CENTRE) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 2;
      case "hotwater":
        return 2;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BEACON_LAFAYETTE) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BEACON_PENTAGON_I) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 2;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BEACON_PENTAGON_II) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 1;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BEACON_TERRELL_PLACE) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 4;
      case "hotwater":
        return 1;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BSCP7_SOUTH_LASALLE) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 3;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BSCP7_NORTH_STATE) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BSCP7_PACIFIC_CONCOURSE_5220) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 1;
      case "lighting":
        return 3;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BSCP7_PACIFIC_CONCOURSE_5230) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 1;
      case "hotwater":
        return 1;
      case "controls":
        return 2;
      case "envelope":
        return 1;
      case "lighting":
        return 3;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.STATE_JV_STATE_STREET_53) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BSCP8_BROADWAY) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 3;
      case "cooling":
        return 4;
      case "hotwater":
        return 3;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.AEI_BROOKFIELD_TURTLE_COVE) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 3;
      case "envelope":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.TMO_CLARY_CROSSING_SENIOR) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 2;
      case "controls":
        return 4;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.TMO_LASALLE_APARTMENTS) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 3;
      case "controls":
        return 2;
      case "envelope":
        return 3;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.TMO_CLARY_CROSSING_APARTMENTS) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 2;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.INFRASTRUCTURE_ONTARIO_GREENVILLE) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.INFRASTRUCTURE_ONTARIO_MAPLEHURST) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 2;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.INFRASTRUCTURE_ONTARIO_GOVERNMENT) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 3;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_PASUBIO_CENTRALE) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 4;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_ONE_BOSTON_PLACE) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 3;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_KARPERSTRAAT_HQ) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "envelope":
        return 3;
      case "lighting":
        return 3;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_SUNNYVALE_APARTMENTS_NORTH) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 3;
      case "envelope":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_SUNNYVALE_APARTMENTS_SOUTH) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_NEW_YORK_AVE) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  } else {
    return null;
  }
}

export function financialROIRank(system: string, spaceToken: string) {
  if (spaceToken === SystemOverrideTokens.CROWN_UNIVERSITY_AVENUE) {
    return system === "total" ? 3 : null;
  }

  if (spaceToken === SystemOverrideTokens.CROWN_KING_WEST) {
    return system === "total" ? 1 : null;
  }

  if (spaceToken === SystemOverrideTokens.CROWN_SHEPPARD) {
    return system === "total" ? 2 : null;
  }

  if (spaceToken === SystemOverrideTokens.DEMO_SPACE_TOKEN_MADISON) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_650_TOWNSEND) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_BANK_OF_AMERICA_TOWER) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_BLANCHARD_PLAZA) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 1;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_GATEWAY_TOWER_5) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 1;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_METRO_PARK_2) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_METRO_PARK_4) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_METRO_PARK_7) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 1;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_METRO_PARK_8) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_101_NORTH_BRAND) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCAL_METROPOLITAN_PARK_EAST) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_7_ONE_NORTH_DEARBORN) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_7_AMA_PLAZA) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 1;
      case "controls":
        return 1;
      case "envelope":
        return 2;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_7_1055_E_COLORADO) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 2;
      case "cooling":
        return 1;
      case "hotwater":
        return 1;
      case "controls":
        return 1;
      case "envelope":
        return 3;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_7_575_5TH_AVE) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_1) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 3;
      case "controls":
        return 3;
      case "envelope":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_2) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_3) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 3;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_4) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 1;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_5) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 1;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_6) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_7) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 3;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_8) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 3;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_10) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 3;
      case "lighting":
        return 3;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_PEARL_11) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_WELLESLEY_GATEWAY) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 1;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_1800_LARIMER) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_600_CONGRESS) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_800_NORTH_BRAND) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 4;
      case "cooling":
        return 2;
      case "hotwater":
        return 3;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_FOUNDRY_I) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_FOUNDRY_II) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_WELLESLEY_HILLS_II) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 3;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_8_WELLESLEY_HILLS_IV) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.BEACON_BCSP_9_ONE_BRATTLE_SQUARE) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 2;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 3;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.OXFORD_BACKBAY_TOKEN) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_FRANKLIN_TOKEN) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.PRINCIPAL_CODA) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.PRINCIPAL_EDENRED) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 3;
      case "controls":
        return 3;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.PRINCIPAL_STEIGEREILAND) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 2;
      case "controls":
        return 4;
      case "lighting":
        return 4;
      case "envelope":
        return 1;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.CARMEL_TOWERS_AT_RINCON) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.PRINCIPAL_TECH_DISTRIBUTION_CENTER) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 3;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.PRINCIPAL_SOLARIS_KEY) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 1;
      case "controls":
        return 1;
      case "lighting":
        return 4;
      case "envelope":
        return 2;
      default:
        return null;
    }
  }
  // Nuveen
  else if (spaceToken === SystemOverrideTokens.NUVEEN_KIRKLAND) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 3;
      case "controls":
        return 3;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.NUVEEN_DEFOOR) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "lighting":
        return 2;
      case "envelope":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.NUVEEN_EAST_SEGO) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_NYA_TOKEN) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 3;
      case "lighting":
        return 3;
      case "envelope":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_900_16th_TOKEN) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 3;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BEACON_TABOR_CENTRE) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 2;
      case "hotwater":
        return 2;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BEACON_LAFAYETTE) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BEACON_PENTAGON_I) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 1;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BEACON_PENTAGON_II) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 1;
      case "cooling":
        return 2;
      case "hotwater":
        return 1;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BEACON_TERRELL_PLACE) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 4;
      case "hotwater":
        return 1;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BSCP7_SOUTH_LASALLE) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 3;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BSCP7_NORTH_STATE) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BSCP7_PACIFIC_CONCOURSE_5220) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 4;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 1;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BSCP7_PACIFIC_CONCOURSE_5230) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 4;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 1;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.STATE_JV_STATE_STREET_53) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.BSCP8_BROADWAY) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 3;
      case "cooling":
        return 4;
      case "hotwater":
        return 3;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.AEI_BROOKFIELD_TURTLE_COVE) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.TMO_CLARY_CROSSING_SENIOR) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 3;
      case "controls":
        return 4;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.TMO_LASALLE_APARTMENTS) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 3;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.TMO_CLARY_CROSSING_APARTMENTS) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 3;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.INFRASTRUCTURE_ONTARIO_GREENVILLE) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.INFRASTRUCTURE_ONTARIO_MAPLEHURST) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.INFRASTRUCTURE_ONTARIO_GOVERNMENT) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_PASUBIO_CENTRALE) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 4;
      case "cooling":
        return 2;
      case "hotwater":
        return 3;
      case "controls":
        return 4;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_ONE_BOSTON_PLACE) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_KARPERSTRAAT_HQ) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 3;
      case "controls":
        return 4;
      case "envelope":
        return 3;
      case "lighting":
        return 2;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_SUNNYVALE_APARTMENTS_NORTH) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_SUNNYVALE_APARTMENTS_SOUTH) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_NEW_YORK_AVE) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  } else {
    return null;
  }
}
