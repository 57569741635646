/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `FAKE_JOB` - Fake Job
 * * `GET_UTILITY_STATEMENT_PDF` - Get Utility Statement Pdf
 * * `STATEMENT_ZIP_JOB` - Statement Zip Job
 * * `DASHBOARD_PDF_JOB` - Dashboard Pdf Job
 * * `ONBOARDING_STATUS_INGESTION_JOB` - Onboarding Status Ingestion Job
 * * `FETCH_ARCADIA_UTILITY_DATA_JOB` - Fetch Arcadia Utility Data Job
 * * `FETCH_STATEMENT_JOB` - Fetch Statement Job
 * * `FETCH_ENERGY_STAR_UTILITY_DATA` - Fetch Energy Star Utility Data
 * * `UPLOAD_STATEMENT_JOB` - Upload Statement Job
 * * `BACKFILL_ARCADIA_ACCOUNT_DATA_JOB` - Backfill Arcadia Account Data Job
 * * `GENERATE_CRREM_INPUTS_JOB` - Generate Crrem Inputs Job
 * * `EUI_OUTLIER_DETECTION_JOB` - Eui Outlier Detection Job
 * * `DATA_GAP_DETECTION_JOB` - Data Gap Detection Job
 * * `CREATE_SUCCESSOR_UNIT_JOB` - Create Successor Unit Job
 * * `FORMAT_ADDRESS_JOB` - Format Address Job
 * * `PROPERTY_RANKING_JOB` - Property Ranking Job
 * * `ENERGY_STAR_DATA_DELETION_JOB` - Energy Star Data Deletion Job
 * * `ENERGY_STAR_PROPERTY_INGESTION_JOB` - Energy Star Property Ingestion Job
 * * `ENERGY_STAR_METER_DATA_SYNC_AND_DELETE_JOB` - Energy Star Meter Data Sync And Delete Job
 * * `PARTNER_SURVEY_RESPONSE_FETCHING_JOB` - Partner Survey Response Fetching Job
 * * `ANOMALY_DETECTION_JOB` - Anomaly Detection Job
 * * `URJANET_METER_DATA_INGESTION_JOB` - Urjanet Meter Data Ingestion Job
 * * `URJANET_METER_DATA_BACKFILL_JOB` - Urjanet Meter Data Backfill Job
 * * `CREATE_METER_DATA_CALENDARIZED_JOB` - Create Meter Data Calendarized Job
 * * `UPDATE_METER_DATA_CALENDARIZED_JOB` - Update Meter Data Calendarized Job
 * * `DELETE_METER_DATA_CALENDARIZED_JOB` - Delete Meter Data Calendarized Job
 * * `BACKFILL_SITE_V_SITE_JOB` - Backfill Site V Site Job
 * * `PRECOMPUTE_MONTHLY_AGGREGATES_JOB` - Precompute Monthly Aggregates Job
 * * `PARSE_PDF_UTILITY_DATA` - Parse Pdf Utility Data
 */
export enum JobTypeEnum {
    FAKE_JOB = 'FAKE_JOB',
    GET_UTILITY_STATEMENT_PDF = 'GET_UTILITY_STATEMENT_PDF',
    STATEMENT_ZIP_JOB = 'STATEMENT_ZIP_JOB',
    DASHBOARD_PDF_JOB = 'DASHBOARD_PDF_JOB',
    ONBOARDING_STATUS_INGESTION_JOB = 'ONBOARDING_STATUS_INGESTION_JOB',
    FETCH_ARCADIA_UTILITY_DATA_JOB = 'FETCH_ARCADIA_UTILITY_DATA_JOB',
    FETCH_STATEMENT_JOB = 'FETCH_STATEMENT_JOB',
    FETCH_ENERGY_STAR_UTILITY_DATA = 'FETCH_ENERGY_STAR_UTILITY_DATA',
    UPLOAD_STATEMENT_JOB = 'UPLOAD_STATEMENT_JOB',
    BACKFILL_ARCADIA_ACCOUNT_DATA_JOB = 'BACKFILL_ARCADIA_ACCOUNT_DATA_JOB',
    GENERATE_CRREM_INPUTS_JOB = 'GENERATE_CRREM_INPUTS_JOB',
    EUI_OUTLIER_DETECTION_JOB = 'EUI_OUTLIER_DETECTION_JOB',
    DATA_GAP_DETECTION_JOB = 'DATA_GAP_DETECTION_JOB',
    CREATE_SUCCESSOR_UNIT_JOB = 'CREATE_SUCCESSOR_UNIT_JOB',
    FORMAT_ADDRESS_JOB = 'FORMAT_ADDRESS_JOB',
    PROPERTY_RANKING_JOB = 'PROPERTY_RANKING_JOB',
    ENERGY_STAR_DATA_DELETION_JOB = 'ENERGY_STAR_DATA_DELETION_JOB',
    ENERGY_STAR_PROPERTY_INGESTION_JOB = 'ENERGY_STAR_PROPERTY_INGESTION_JOB',
    ENERGY_STAR_METER_DATA_SYNC_AND_DELETE_JOB = 'ENERGY_STAR_METER_DATA_SYNC_AND_DELETE_JOB',
    PARTNER_SURVEY_RESPONSE_FETCHING_JOB = 'PARTNER_SURVEY_RESPONSE_FETCHING_JOB',
    ANOMALY_DETECTION_JOB = 'ANOMALY_DETECTION_JOB',
    URJANET_METER_DATA_INGESTION_JOB = 'URJANET_METER_DATA_INGESTION_JOB',
    URJANET_METER_DATA_BACKFILL_JOB = 'URJANET_METER_DATA_BACKFILL_JOB',
    CREATE_METER_DATA_CALENDARIZED_JOB = 'CREATE_METER_DATA_CALENDARIZED_JOB',
    UPDATE_METER_DATA_CALENDARIZED_JOB = 'UPDATE_METER_DATA_CALENDARIZED_JOB',
    DELETE_METER_DATA_CALENDARIZED_JOB = 'DELETE_METER_DATA_CALENDARIZED_JOB',
    BACKFILL_SITE_V_SITE_JOB = 'BACKFILL_SITE_V_SITE_JOB',
    PRECOMPUTE_MONTHLY_AGGREGATES_JOB = 'PRECOMPUTE_MONTHLY_AGGREGATES_JOB',
    PARSE_PDF_UTILITY_DATA = 'PARSE_PDF_UTILITY_DATA',
}
