import { Collection } from "resourcerer";

import {
  carbonROIRank,
  financialROIRank,
  isHardcodedSystemSpace,
  SystemOverrideTokens,
} from "@/js/utils/harcodedValueUtil";

import { type EnhancedSpace, type Ranking } from "@/Api/generated";

export interface RankingCategory {
  category: string;
  category_enabled: boolean;
  category_text: string;
  category_unit: string;
}

// this are listed here because the API doesn't have them serialized yet
interface Act2Roi extends Ranking {
  controls_cost_model: Ranking;
  cooling_cost_model: Ranking;
  dhw_cost_model: Ranking;
  envelope_cost_model: Ranking;
  heating_cost_model: Ranking;
  lighting_cost_model: Ranking;
}

interface Act2CarbonRoi extends Ranking {
  controls_carbon_roi_model: Ranking;
  cooling_carbon_roi_model: Ranking;
  dhw_carbon_roi_model: Ranking;
  envelope_carbon_roi_model: Ranking;
  heating_carbon_roi_model: Ranking;
  lighting_carbon_roi_model: Ranking;
}

interface Rankings {
  act2_roi: Act2Roi;
  act2_carbon_roi: Act2CarbonRoi;
  [key: string]: Ranking;
}

// the generated EnhancedSpace type doesn't include the nested rankings under the act2 rankings
export interface ClientEnhancedSpace extends Omit<EnhancedSpace, "property_ranking"> {
  property_ranking: Rankings | null;
}

/**
 * A critical resource for Cambio, when passed the `include_ranking` param each property has its
 * act 2 rankings attached. For some properties (I think mostly for demos?) we hard-code those act 2
 * values, which makes this class look more complicated than it actually is.
 */
export default class PropertiesCollection extends Collection<ClientEnhancedSpace> {
  propertyRankingCategories: RankingCategory[];

  parse(response: {
    properties: ClientEnhancedSpace[];
    property_ranking_categories: RankingCategory[];
  }) {
    this.propertyRankingCategories = response.property_ranking_categories;

    return response.properties.map((space) => ({
      ...space,
      property_ranking:
        shouldUseHardCodedValues(space) ?
          {
            // here is where we are doing constant overrides. TODO: remove when we rely only on API data
            ...space.property_ranking,
            act2_carbon_roi: {
              ...space.property_ranking.act2_carbon_roi,
              rank: carbonROIRank("total", space.token),
              rank_text: hardCodeRankToStatus(carbonROIRank("total", space.token)),
              controls_carbon_roi_model: {
                // @ts-ignore
                ...space.property_ranking.act2_carbon_roi.controls_carbon_roi_model,
                rank: carbonROIRank("controls", space.token),
              },
              cooling_carbon_roi_model: {
                // @ts-ignore
                ...space.property_ranking.act2_carbon_roi.cooling_carbon_roi_model,
                rank: carbonROIRank("cooling", space.token),
              },
              dhw_carbon_roi_model: {
                // @ts-ignore
                ...space.property_ranking.act2_carbon_roi.dhw_carbon_roi_model,
                rank: carbonROIRank("hotwater", space.token),
              },
              envelope_carbon_roi_model: {
                // @ts-ignore
                ...space.property_ranking.act2_carbon_roi.envelope_carbon_roi_model,
                rank: carbonROIRank("envelope", space.token),
              },
              heating_carbon_roi_model: {
                // @ts-ignore
                ...space.property_ranking.act2_carbon_roi.heating_carbon_roi_model,
                rank: carbonROIRank("heating", space.token),
              },
              lighting_carbon_roi_model: {
                // @ts-ignore
                ...space.property_ranking.act2_carbon_roi.lighting_carbon_roi_model,
                rank: carbonROIRank("lighting", space.token),
              },
            },
            act2_roi: {
              ...space.property_ranking.act2_roi,
              rank: financialROIRank("total", space.token),
              rank_text: hardCodeRankToStatus(financialROIRank("total", space.token)),
              controls_cost_model: {
                // @ts-ignore
                ...space.property_ranking.act2_roi.controls_cost_model,
                rank: financialROIRank("controls", space.token),
              },
              cooling_cost_model: {
                // @ts-ignore
                ...space.property_ranking.act2_roi.cooling_cost_model,
                rank: financialROIRank("cooling", space.token),
              },
              dhw_cost_model: {
                // @ts-ignore
                ...space.property_ranking.act2_roi.dhw_cost_model,
                rank: financialROIRank("hotwater", space.token),
              },
              envelope_cost_model: {
                // @ts-ignore
                ...space.property_ranking.act2_roi.envelope_cost_model,
                rank: financialROIRank("envelope", space.token),
              },
              heating_cost_model: {
                // @ts-ignore
                ...space.property_ranking.act2_roi.heating_cost_model,
                rank: financialROIRank("heating", space.token),
              },
              lighting_cost_model: {
                // @ts-ignore
                ...space.property_ranking.act2_roi.lighting_cost_model,
                rank: financialROIRank("lighting", space.token),
              },
            },
          }
        : space.property_ranking,
    }));
  }

  url() {
    return "/api/properties";
  }

  static idAttribute = "token";

  static dependencies = [
    "include_area",
    "include_ranking",
    "organization_token",
    "space_type",
    "is_acquisition_property",
  ];
}

export function hardCodeRankToStatus(rank: number) {
  switch (rank) {
    case 1:
      return "Extreme";
    case 2:
      return "High";
    case 3:
      return "Moderate";
    case 4:
      return "Low";
    default:
      return "N/A";
  }
}

function shouldUseHardCodedValues(space: EnhancedSpace) {
  return (
    space.property_ranking &&
    isHardcodedSystemSpace(space.token) &&
    // this is a special property used in demos where we don't hardcode here,
    // we use what we get from the API. So don't override! We do override in
    // one place, in the heat map
    space.token !== SystemOverrideTokens.DEMO_SPACE_TOKEN_MADISON
  );
}
