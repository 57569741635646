import type { CarbonScope } from "@/Api/generated/models/CarbonScope";

import { Collection } from "resourcerer";

export default class ScopeBreakdownCollection extends Collection<CarbonScope> {
  url({ organization_token }: { organization_token: string }) {
    return `/api/organization/${organization_token}/scope_breakdown`;
  }

  static dependencies = [
    "organization_token",
    "space_token",
    "start_date",
    "end_date",
    "campus_token",
    "partner_tokens",
    "asset_class_broad_categories",
  ];
}
