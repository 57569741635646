import {
  cloneElement,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import Portal, { usePortalContext } from "@/components/Portal";

import useResizeObserver from "@/js/hooks/useResizeObserver";
import { classnames } from "@/js/utils/cambio";

export type Alignments = "left" | "right" | "center" | "extents";
export type Positions = "top" | "bottom" | "left" | "right";

type Rect = {
  top: number;
  left: number;
};

type RectArgs = [
  HTMLElement,
  HTMLElement,
  HTMLElement,
  { alignment: Alignments; position: Positions },
];

interface HovercardProps {
  /** Element relative to which to position the hovercard */
  anchorEl: HTMLElement;
  children: ReactNode | (() => ReactNode);
  className?: string;
  alignment?: Alignments;
  position?: Positions;
  /** When true, this component measures its children and then renders them in a portal */
  visible?: boolean;
}

function getVerticalPosition(
  ...[hovercardEl, anchorEl, parentEl, { alignment, position }]: RectArgs
) {
  const anchorRect = anchorEl.getBoundingClientRect();
  const parentRect = parentEl.getBoundingClientRect();

  switch (position) {
    case "bottom":
    default:
      return { top: anchorRect.bottom + parentEl.scrollTop - parentRect.top };
  }
}

function getHorizontalPosition(
  ...[hovercardEl, anchorEl, parentEl, { alignment, position }]: RectArgs
) {
  const hovercardRect = hovercardEl.getBoundingClientRect();
  const anchorRect = anchorEl.getBoundingClientRect();
  const parentRect = parentEl.getBoundingClientRect();

  switch (alignment) {
    case "right":
      return {
        left:
          anchorRect.left +
          anchorRect.width +
          parentEl.scrollLeft -
          (parentRect.left + hovercardRect.width),
      };
    case "extents":
      return {
        left: anchorRect.left + parentEl.scrollLeft - parentRect.left,
        width: anchorRect.width,
      };
    case "left":
    default:
      return { left: anchorRect.left + parentEl.scrollLeft - parentRect.left };
  }
}

/**
 * Given the anchor, the hovercard, and the parent that the hovercard will be rendered within,
 * this function calculates the top/left positioning of the hovercard.
 *
 * This is super basic right now. It only supports a couple alignments and positioning. We
 * will expand on this function's capabilities as we go.
 */
export function calculateRect(...args: RectArgs): Rect {
  return {
    ...getVerticalPosition(...args),
    ...getHorizontalPosition(...args),
  };
}

/**
 * Renders content within a Portal, positioned and aligned relative to an anchor element and within
 * a parent element. This is extremely basic right now, but in the future we will support multiple
 * positions and alignments, and we'll infer whether we should flip positions based on available
 * space in the parent.
 */
const Hovercard = forwardRef<HTMLElement, HovercardProps>(
  ({ anchorEl, alignment = "left", children, className, position = "bottom", visible }, ref) => {
    // this is only for the pre-rendering
    const [hovercardNode, setHovercardNode] = useState<HTMLDivElement>();
    /** What we set on the hovercard to position it correctly */
    const [rect, setRect] = useState<Rect>();
    /** Flag to tell the component to re-mount its invisible container and generate a new rect */
    const [recalc, setRecalc] = useState(false);
    const parentEl = usePortalContext().parent?.current || document.body;

    const getChild = ({ prerender } = { prerender: false }) => (
      <div className={classnames("Hovercard", className)} {...(!prerender ? { style: rect } : {})}>
        {typeof children === "function" ? children() : children}
      </div>
    );

    const onResize = useCallback(() => (visible ? setRecalc(true) : null), [visible]);

    /**
     * After `visible` is set to true, we render this component's children inside a container that
     * is way off screen so that we can measure it. Then we set its measurements as state, remove
     * the invisible container, and render the positioned hovercard within a portal.
     */
    useLayoutEffect(() => {
      if (hovercardNode) {
        setRect(calculateRect(hovercardNode, anchorEl, parentEl, { alignment, position }));
        setRecalc(false);
      }
    }, [hovercardNode]);

    /**
     * Adjusts positioning whenever the window size changes because the anchor position may have
     * changed.
     */
    useEffect(() => {
      window.addEventListener("resize", onResize);

      return () => window.removeEventListener("resize", onResize);
    }, [onResize]);

    /**
     * The window resize handler recalculates because the anchor's position may have changed. The
     * ResizeObserver does it when an anchor's shape has changed.
     */
    useResizeObserver(anchorEl, onResize);

    return (
      <>
        {visible && (!rect || recalc) ?
          <div
            ref={(node) => setHovercardNode(node?.firstChild as HTMLDivElement)}
            className="invisible-container"
          >
            {getChild({ prerender: true })}
          </div>
        : null}
        <Portal timeout={125} visible={visible && !!rect} onUnmount={() => setRect(null)}>
          {cloneElement(getChild(), { ref })}
        </Portal>
      </>
    );
  },
);

export default Hovercard;
