/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `URJANET_API` - Urjanet Api
 * * `INTERNAL_SPREADSHEET` - Internal Spreadsheet
 * * `ENERGY_STAR` - Energy Star
 * * `ONBOARDING_FLOW` - Onboarding Flow
 * * `PM_ONBOARDING` - Pm Onboarding
 */
export enum SourceTypeEnum {
    URJANET_API = 'URJANET_API',
    INTERNAL_SPREADSHEET = 'INTERNAL_SPREADSHEET',
    ENERGY_STAR = 'ENERGY_STAR',
    ONBOARDING_FLOW = 'ONBOARDING_FLOW',
    PM_ONBOARDING = 'PM_ONBOARDING',
}
