import type { InputProps } from "@/components/Input";
import type { SelectableListGroup, SelectableListItem } from "@/components/SelectableList";
import type { ForwardedRef, KeyboardEvent, MouseEvent } from "react";

import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";

import IconButton from "@/components/Button/IconButton";
import EmptyState, { ErrorState } from "@/components/EmptyState";
import Hovercard from "@/components/Hovercard";
import SearchInput from "@/components/Input/SearchInput";
import SelectableList from "@/components/SelectableList";
import Spinner from "@/components/Spinner";

import { classnames, noop } from "@/js/utils/cambio";

export interface Option extends Omit<SelectableListItem, "key"> {
  value: SelectableListItem["key"];
}

export interface OptionGroup extends Omit<SelectableListGroup, "items"> {
  items: Option[];
}

interface SearchSelectProps {
  autofocus?: boolean;
  /** this will skip auto-sizing of the component (when we do it) and it will take up full-width of its parent */
  block?: boolean;
  /** When this is true, the user can write-in an option and select that */
  canAddNew?: boolean;
  disabled?: boolean;
  /** true when failing form validation */
  invalid?: boolean;
  id?: string;
  options?: Option[];
  /** Passing this will take precedence over `options` */
  optionGroups?: OptionGroup[];
  /** use this for any side effects from changing search text, ie for remote fetching */
  onChange?: (val: string) => void;
  /** callback for actually selecting an item */
  onSelect?: (val: Option["value"], evt: MouseEvent | KeyboardEvent) => void;
  placeholder?: string;
  value?: Option["value"];
  size?: InputProps["size"];
  /**
   * this should be true when fetching results via an API instead of just client-side filtering.
   * when you use this, you should use the loading states below it, as well.
   */
  remote?: boolean;
  isLoading?: boolean;
  hasErrored?: boolean;
  hasInitiallyLoaded?: boolean;
  hasLoaded?: boolean;
}

/**
 * This is a SearchSelect component that has a couple different uses in our app:
 *
 * 1. You can search across a set of results and click the result as a CTA
 * 2. You can search across a set of options and choose one as a form input
 * 3. You can search for a set of remote results based on the input field
 *
 * It accepts a list of options or a list of option groups.
 */
const SearchSelect = forwardRef(
  (
    {
      autofocus,
      block = true,
      canAddNew,
      disabled,
      hasErrored,
      hasInitiallyLoaded = true,
      hasLoaded = true,
      id,
      invalid,
      isLoading,
      onChange,
      onSelect = noop,
      options = [],
      optionGroups = [],
      placeholder = "Search",
      remote,
      size,
      value,
    }: SearchSelectProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [addedData, setAddedData] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    /**
     * This is a hack to keep our matching items list stable after we select an item after typing
     * in a partial result. Otherwise our list would suddenly show just that single item as the
     * hovercard fades out.
     */
    const [tempMatchingItems, setTempMatchingItems] = useState<{
      items?: Option[];
      groups?: OptionGroup[];
    }>({});
    const searchContainerRef = useRef<HTMLDivElement>();
    const _inputRef = useRef<HTMLInputElement>();
    const inputRef = ref || _inputRef;

    // auto-disable if we only have one option and we've chosen that option
    const isDisabled =
      disabled || (options.length === 1 && !canAddNew && value === options[0].value);

    // NOTE: there is no ability to add an item to a SearchSelect that uses OptionGroups
    const optionsSet = options.concat(addedData.map((item) => ({ value: item, display: item })));

    const currentItem =
      optionGroups.length ?
        optionGroups.flatMap(({ items }) => items).find((option) => option.value === value)
      : optionsSet.find((option) => option.value === value);
    const currentItemDisplay = currentItem?.display || "";

    const [searchText, setSearchText] = useState("" + currentItemDisplay);
    const optionsSetIncludesText = optionsSet
      .map(({ display }) => display.toLowerCase())
      .includes(searchText.toLowerCase());
    const doesItemMatchSearchText = ({ display, secondaryDisplay = "" }: Option) =>
      display.concat(secondaryDisplay).toLowerCase().includes(searchText.toLowerCase());

    const matchingGroups =
      tempMatchingItems.groups ||
      optionGroups
        .map((group) => ({
          ...group,
          items: group.items.filter(doesItemMatchSearchText),
        }))
        .filter(({ items }) => items.length);
    const matchingItems =
      tempMatchingItems.items ||
      (remote ? optionsSet : (
        optionsSet
          .filter(({ display, secondaryDisplay = "" }) =>
            display.concat(secondaryDisplay).toLowerCase().includes(searchText.toLowerCase()),
          )
          .concat(
            canAddNew && searchText && !optionsSetIncludesText ?
              [{ value: `add-option ${searchText}"`, display: `Add "${searchText}"` }]
            : [],
          )
      ));

    const noMatchingItems =
      hasLoaded &&
      ((optionGroups.length && !matchingGroups.length) ||
        (options.length && !matchingItems.length));

    // this defer is to ensure that focusing the input doesn't interfere with any other actions
    const refocusInput = () =>
      window.setTimeout(() => "current" in inputRef && inputRef.current.focus(), 0);

    const _onSelect = (val: string | number | null, evt: MouseEvent | KeyboardEvent) => {
      if (val && typeof val === "string" && val.includes("add-option")) {
        setAddedData((current) => [...new Set(current.concat(searchText))]);
        onSelect(searchText, evt);
      } else {
        onSelect(val, evt);
      }

      if (open && val) {
        setTempMatchingItems({ groups: matchingGroups, items: matchingItems });
      }
    };

    const onKeyDown = (evt: KeyboardEvent) => {
      switch (evt.key) {
        case "Tab": {
          setOpen(false);
          break;
        }
        case "Enter": {
          const firstMatchingItem =
            optionGroups.length ? matchingGroups[0]?.items[0] : matchingItems[0];

          if (open && firstMatchingItem) {
            evt.preventDefault();
            _onSelect(firstMatchingItem.value, evt);
            setOpen(false);
          }

          break;
        }
        case "Escape": {
          evt.stopPropagation();
          setSearchText("");

          break;
        }
        // ensure that if this is closed that when they start typing it opens again
        // if this is already open it will be a noop
        default: {
          if (searchText) {
            setOpen(true);
          }
        }
      }
    };

    /**
     * Closing the results pane on blurring the input is too aggressive because we want to be able
     * to navigate the results pane without it closing. So we manage auto-closing the pane if we
     * click outside it, hit the tab button, etc.
     */
    useEffect(() => {
      const onClickOutside = (evt: globalThis.MouseEvent) => {
        searchContainerRef.current.contains(evt.target as Element) ? null : setOpen(false);
      };

      document.addEventListener("click", onClickOutside);

      return () => document.removeEventListener("click", onClickOutside);
    }, []);

    /**
     * Need to ensure that whenever onSelect is called, maybe with the text of a partial result +
     * hitting Enter, that the input field reflects the new chosen value.
     */
    useLayoutEffect(() => {
      if (currentItemDisplay !== searchText) {
        setSearchText(currentItemDisplay);
      }
    }, [currentItemDisplay]);

    /**
     * This is a a hack to keep the matching items stable when you select an item after typing in
     * partial results. Once you click and the input text changes, technically we should only have
     * one matching item. And seeing the list jump from n items to 1 as the hovercard fades out is
     * a funny UX. So we use this ephemeral state to keep the items stable while the hovercard
     * fades out.
     */
    useEffect(() => {
      if (Object.keys(tempMatchingItems).length) {
        const timeout = window.setTimeout(() => setTempMatchingItems({}), 500);

        return () => window.clearTimeout(timeout);
      }
    }, [tempMatchingItems]);

    return (
      <div ref={searchContainerRef} className={classnames("SearchSelect", { invalid, block })}>
        <SearchInput
          autofocus={autofocus}
          ref={inputRef}
          autocomplete="off"
          disabled={isDisabled}
          id={id}
          onFocus={() => (!remote || searchText ? setOpen(true) : null)}
          onChange={(val) => {
            setSearchText(val);
            onChange?.(val);

            if (remote) {
              setOpen(!!val);
            }
          }}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          size={size}
          value={searchText}
        />
        <IconButton
          disabled={isDisabled}
          icon={searchText ? "x" : "chevron-down"}
          size={searchText ? "small" : undefined}
          onClick={(evt) => {
            if (searchText) {
              if (!remote) {
                _onSelect(null, evt);
              }

              onChange?.("");
              setSearchText("");
            }

            refocusInput();
          }}
        />
        <Hovercard
          alignment="extents"
          anchorEl={searchContainerRef.current}
          className={classnames("search-select-hovercard", {
            empty: !hasInitiallyLoaded || noMatchingItems,
          })}
          visible={open}
        >
          {isLoading ?
            <Spinner flavor="overlay" />
          : null}
          {hasErrored ?
            <ErrorState />
          : null}
          {hasInitiallyLoaded ?
            noMatchingItems ?
              <EmptyState message="No matching items." />
            : <SelectableList
                groups={matchingGroups.map(({ items, ...group }) => ({
                  ...group,
                  items: items.map(({ value, ...item }) => ({ ...item, key: value })),
                }))}
                items={matchingItems.map(({ value, ...item }) => ({ ...item, key: value }))}
                onClick={(item, evt) => _onSelect(item.key, evt)}
                onFocusEndOfList={refocusInput}
              />

          : null}
        </Hovercard>
      </div>
    );
  },
);

export default SearchSelect;
