import type { SyncOptions } from "resourcerer";

import uniqueId from "lodash/uniqueId";
import { Collection, Model, sync } from "resourcerer";

import { isInBossMode, isSpoofing } from "@/js/utils/authentication";

/**
 * Spoof guard that intercepts write requests when spoofing and auto-resolves them. Bonus: If POST-ing
 * something without an id, it adds a fake one for better UX while spoofing.
 *
 * Note that this should really never be a Collection, but adding it here just in case--so we don't
 * have any surprises.
 *
 * One other thing to note: we actually pass requests through while spoofing in boss mode. This is
 * because we have a second spoof guard at a lower level--our global fetch handler--and we don't
 * want to show two confirmation modals.
 */
Model.prototype.sync = Collection.prototype.sync = function (
  model: Model | Collection,
  options: SyncOptions & { spoofBypass?: boolean },
) {
  if (
    options.method &&
    options.method !== "GET" &&
    isSpoofing() &&
    // bypass this check if in boss mode -- in our global fetch handler,
    // we will show a confirmation modal
    !isInBossMode() &&
    !options.spoofBypass
  ) {
    if (options.method === "POST" && model instanceof Model && !model.id) {
      const idAttribute =
        // @ts-ignore not sure why idAttribute doesn't register on the constructor.
        model.collection?.constructor?.idAttribute || model.constructor.idAttribute;

      model.set({ [idAttribute]: uniqueId() });
    }

    return Promise.resolve([
      options.params || model.toJSON(),
      new Response(new Blob(), { status: 200 }),
    ]);
  }

  return sync.call(this, model, options);
};
