import type { ReactNode } from "react";

import flowRight from "lodash/flowRight";
import Link from "next/link";
import { useRouter } from "next/router";
import { memo, useEffect, useRef } from "react";

import IconButton from "@/components/Button/IconButton";
import HovercardToggle from "@/components/HovercardToggle";
import { withModalContextProvider } from "@/components/Modal/ModalContext";
import { withNotificationContextProvider } from "@/components/Notification/NotificationContext";
import { PortalContext } from "@/components/Portal";
import SelectableList from "@/components/SelectableList";
import { withSidePanelContextProvider } from "@/components/SidePanel/SidePanelContext";

import { NavItems, SecondaryNavItems } from "@/js/constants/nav";
import { getPortfolioOrg, getSubportfolios } from "@/js/core/coreResources";
import { withForbidsPersona, withRequiresCustom, withRequiresLogin } from "@/js/core/gatekeepers";
import useOverflow from "@/js/hooks/useOverflow";
import { logout } from "@/js/utils/authentication";
import { classnames } from "@/js/utils/cambio";
import { generateOrgHref } from "@/js/utils/navigation";

import { PersonaEnum } from "@/Api/generated";
import CambioLogo from "@/images/logo.svg";
import SpoofModal from "@/layouts/AppLayout/SpoofModal";
import { isInOrgContext as _isInOrgContext } from "@/layouts/AppLayout/utils";

import { useAppContext, withAppContext } from "./AppContext";
import NavItem from "./NavItem";
import PortfolioPicker from "./PortfolioPicker";

/**
 * Within the application, we can be in or out of an org context. In an org context, there is a
 * "selected" organization token available, which could be either a subportfolio or a portfolio
 * org token. The former can be taken from the url, but the latter won't be present there.
 *
 * When we are on the portfolios or partners pages, for example, we are out of an org context.
 */
function AppLayout({ children }: { children: ReactNode }) {
  const { featureConfigurations, areSubFeatureFlagsLoading } = useAppContext();
  const router = useRouter();

  const subPortfolioToken =
    (router.query.organization_token as string) ||
    // in the case that we're in an org with just one subportfolio, this won't matter much for
    // navigation, but it will tell the component to use the sub feature flags for locking the
    // nav items, which we want in this case..
    (getSubportfolios().length === 1 ? getSubportfolios()[0].organization__token : undefined);
  const spaceToken = router.query.space_token as string;

  /**
   * The following code exists because we want to be able to show the side nav links in the context
   * of the last org (portfolio or subportfolio) the user has seen when they navigate to a page
   * that is not in an org context (like portfolios page or partners page). So the "current org
   * context" will diverge from what is in the url and we can no longer simply read from it; we have
   * to store the info in a ref.
   */
  // this is the context we'll consider ourselves in for our nav links. We'll start with whatever is
  // in the url (or if nothing, the portfolio token)
  const currentOrgRef = useRef<string>(subPortfolioToken);
  // we are in an org context if we are on a page that requires an org token, which can either be
  // subportfolio, in which case we'll have the organization_token in the url, or the portfolio,
  // which is harder because there is no org token in the url. EXCEPTION: property details pages
  // may be reached via a portfolio context but they will still have the org token in the url. In
  // this case we want to use the currentOrgRef for our context, so consider us out of an org
  // context in that case. For example, /partners and /portfolio pages would be out of org context.
  const isInOrgContext = !!_isInOrgContext(router) && !spaceToken;

  // this selectedOrgToken is used in the side nav only and is referenced even when it diverges from
  // the url (out of an org context). in the rest of the app, the selected org would be undefined if
  // out of an org context, but here we want to retain the "last seen".
  const selectedOrgToken =
    (isInOrgContext ? subPortfolioToken : currentOrgRef.current) ||
    getPortfolioOrg().organization__token;
  const mainRef = useRef<HTMLElement>();
  const { overflowClassNames, onScroll } = useOverflow(mainRef);

  // keep a reference to the "last seen" org context so that if the user navigates to a page that
  // is not in an org context, the previous one will be shown still. Extra wrinkle: if we navigate
  // to a property detail page, we want to stay in the same org context we came from, even though
  // the url may have a new subportfolio token.
  useEffect(() => {
    if (isInOrgContext && !spaceToken) {
      currentOrgRef.current = selectedOrgToken;
    }
  }, [selectedOrgToken, isInOrgContext, spaceToken]);

  return (
    <div className="AppLayout">
      <header>
        <Link href="/">
          <CambioLogo className="cambio-logo" />
        </Link>
        <HovercardToggle
          hovercardClassName="account-hovercard"
          contents={
            <SelectableList
              items={[
                {
                  key: "account",
                  href: "/account",
                  display: "Account",
                },
                {
                  key: "signout",
                  display: "Sign out",
                  onClick: () =>
                    logout().catch((error) => {
                      /* eslint-disable */
                      console.error("Error:", error);
                    }),
                },
              ]}
            />
          }
          alignment="right"
        >
          <IconButton icon="user-circle" size="large" />
        </HovercardToggle>
      </header>
      <aside>
        <nav>
          <PortfolioPicker selectedOrgToken={selectedOrgToken} />
          <ul>
            {NavItems.map((item, i) => (
              <NavItem
                key={item.name}
                {...item}
                href={generateOrgHref(item.href, selectedOrgToken)}
                locked={
                  // TODO: rm the PTNZ lock when it's ready at the total-portfolio level,
                  // that will make this logic much less confusing
                  (/path-to-net-zero/.test(item.href) &&
                    selectedOrgToken === getPortfolioOrg().organization__token) ||
                  ((selectedOrgToken === getPortfolioOrg().organization__token ||
                    (subPortfolioToken && !areSubFeatureFlagsLoading)) &&
                    item.forbidsFeatures?.some((feature) => featureConfigurations[feature])) ||
                  item.requiresFeatures?.some((feature) => !featureConfigurations[feature])
                }
                selected={isNavItemSelected(
                  item.href,
                  router.asPath.split("?")[0],
                  subPortfolioToken,
                )}
              />
            ))}
          </ul>
          <hr />
          <ul>
            {SecondaryNavItems.filter(
              (item) =>
                !item.requiresFeatures?.some((feature) => !featureConfigurations[feature]) &&
                !item.forbidsFeatures?.some((feature) => featureConfigurations[feature]) &&
                (!featureConfigurations.TEMP_ORG_LEVEL_NEW_USER_PERMISSIONS_ENABLED ||
                  !item.requiresPermission ||
                  item.requiresPermission()),
            ).map((item, i) => (
              <NavItem
                key={item.name}
                {...item}
                href={item.href}
                selected={item.href === router.asPath.split("?")[0]}
              />
            ))}
          </ul>
        </nav>
      </aside>
      <main ref={mainRef} className={classnames(overflowClassNames)} onScroll={onScroll}>
        <PortalContext.Provider value={{ parent: mainRef }}>{children}</PortalContext.Provider>
      </main>
      <SpoofModal />
    </div>
  );
}

/**
 * TODO: IMO, it would be easier to have keys associated with our different pages and keep a list
 * of which pages belong to which nav item. I think that inferring from the url can be fragile. For
 * now, though, an item is selected if the current url path has any part of its href.
 */
function isNavItemSelected(href: string, pathname: string, subPortfolioToken?: string) {
  // with single-subs, the sub token will be present but the url path won't have it
  const pathMatchingIndex = subPortfolioToken && getSubportfolios().length > 1 ? 2 : 1;

  return pathname.split("/")[pathMatchingIndex] === href.split("/")[1];
}

/**
 * Attach some gatekeepers to the AppLayout
 */
export default flowRight(
  // this must get executed first because after this we assume the core resources exist
  withRequiresLogin,
  withForbidsPersona(PersonaEnum.PROPERTY_MANAGER),
  // if they added a bad org id in the url, boot them home
  withRequiresCustom({
    test: ({ router, organizations }) =>
      !router.query.organization_token ||
      !!organizations.find((org) => org.organization__token === router.query.organization_token),
  }),
  withAppContext,
  withNotificationContextProvider,
  withModalContextProvider,
  withSidePanelContextProvider,
)(memo(AppLayout));
