/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `COMMON_AREA` - Common Area
 * * `VACANT` - Vacant
 * * `OWNED_LEASEBACK` - Owned Leaseback
 * * `LEASED` - Leased
 * * `SUBLEASED` - Subleased
 * * `TENANT_UNIT` - Tenant Unit
 */
export enum UnitTypeEnum {
    COMMON_AREA = 'COMMON_AREA',
    VACANT = 'VACANT',
    OWNED_LEASEBACK = 'OWNED_LEASEBACK',
    LEASED = 'LEASED',
    SUBLEASED = 'SUBLEASED',
    TENANT_UNIT = 'TENANT_UNIT',
}
