/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `BS` - Building Science API
 * * `CA` - Cambio Application
 * * `ME` - Manual Edit
 */
export enum SourceE49Enum {
    BS = 'BS',
    CA = 'CA',
    ME = 'ME',
}
