import type { IconsType } from "@/components/SvgIcon";

import { loadScripts } from "@/js/core/analytics";
import { getEffectiveUser } from "@/js/core/coreResources";

import { FeatureFlags } from "../models/types";
import { hasFullPortfolioAccess } from "../utils/permissions";

export type NavItem = {
  name: string;
  href?: string;
  onClick?: () => void;
  icon: IconsType;
  forbidsFeatures?: Array<keyof FeatureFlags>;
  requiresFeatures?: Array<keyof FeatureFlags>;
  requiresPermission?: (...args: any[]) => void;
};

export const NavItems: NavItem[] = [
  {
    name: "Dashboard",
    href: "/dashboard",
    icon: "home",
  },
  {
    name: "Path to Net Zero",
    href: "/path-to-net-zero/carbon",
    icon: "chart-line-down",
    requiresFeatures: ["PATH_TO_NET_ZERO_ORG_LEVEL_ENABLED"],
  },
  {
    name: "Properties",
    href: "/properties",
    icon: "buildings",
  },
  {
    name: "Projects",
    href: "/projects",
    icon: "clipboard",
    requiresFeatures: ["PROJECTS_PAGE_ORG_LEVEL_ENABLED"],
  },
  {
    name: "Reports",
    href: "/reports",
    icon: "presentation-chart",
  },
];

export const SecondaryNavItems: NavItem[] = [
  {
    name: "Portfolio",
    href: "/portfolio",
    icon: "globe",
    requiresPermission: hasFullPortfolioAccess,
  },
  {
    name: "Partners",
    href: "/partners",
    icon: "users-three",
    requiresFeatures: ["ORG_LEVEL_PARTNER_PORTAL_ENABLED"],
    requiresPermission: hasFullPortfolioAccess,
  },
  {
    name: "Support",
    icon: "help-circle",
    onClick: () => {
      loadScripts.intercom(getEffectiveUser().toJSON());
      window.Intercom("show");
    },
  },
];
