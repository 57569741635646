import type { IconsType } from "@/components/SvgIcon";

import { MouseEventHandler } from "react";

import SvgIcon from "@/components/SvgIcon";

import { classnames } from "@/js/utils/cambio";

interface ButtonProps {
  className?: string;
  disabled?: boolean;
  label?: string;
  onClick?: MouseEventHandler;
  icon: IconsType;
  size?: "small" | "medium" | "large";
}

/**
 * Use for any icon that is also clickable.
 */
export default function IconButton({ size = "medium", ...props }: ButtonProps) {
  return (
    <button
      aria-label={props.label || props.icon}
      className={classnames("IconButton", props.className, { [size]: size !== "medium" })}
      disabled={props.disabled}
      onClick={props.onClick}
      type="button"
    >
      <SvgIcon name={props.icon} />
    </button>
  );
}
