import type { ChangeEvent, HTMLInputTypeAttribute, KeyboardEvent } from "react";

import pick from "lodash/pick";
import uniqueId from "lodash/uniqueId";
import { forwardRef } from "react";

import { classnames } from "@/js/utils/cambio";

import SvgIcon from "../SvgIcon";

export interface InputProps {
  /**
   * Note: We may want to write a custom hook to autofocus after a variable delay. Autofocusing can
   * cause scroll jumps and interrupt animations in jarring ways.
   */
  autofocus?: boolean;
  /** When this is "off", prevents the browser from auto-making suggestions */
  autocomplete?: "off";
  disabled?: boolean;
  id?: string;
  /** This should be true for an input that fails validation. It will have a red border */
  invalid?: boolean;
  onBlur?: (evt: ChangeEvent<HTMLInputElement>) => void;
  onChange: (input: string) => void;
  onFocus?: (evt: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (evt: KeyboardEvent) => void;
  label?: string;
  name?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  size?: "small" | "medium" | "large";
  value?: string;
  step?: string | number;
}

/**
 * Basic input field. This should evolve over time. Accepts a forwarded ref so that parent
 * components can use it imperatively (focus, blur, etc).
 */
const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      autofocus,
      name = "",
      id = `${name}-${uniqueId()}`,
      invalid,
      label,
      onChange,
      type = "text",
      value = "",
      size = "medium",
      ...props
    }: InputProps,
    ref,
  ) => {
    return (
      <>
        <input
          ref={ref}
          aria-label={label}
          autoFocus={autofocus}
          autoComplete={props.autocomplete}
          className={classnames("Input", { invalid, [size]: size !== "medium" })}
          id={id}
          value={value}
          {...pick(props, "onBlur", "onFocus", "onKeyDown", "placeholder", "disabled")}
          name={name}
          onChange={(evt) => onChange(evt.target.value)}
          type={type}
        />
        {invalid ?
          <SvgIcon name="exclamation-circle" />
        : null}
      </>
    );
  },
);

export default Input;
