/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `ELECTRICITY` - Electricity
 * * `NATURAL_GAS` - Natural Gas
 */
export enum RetrofitEnergyImpactAnalysisEnergyTypeEnum {
    ELECTRICITY = 'ELECTRICITY',
    NATURAL_GAS = 'NATURAL_GAS',
}
