import type { EmissionReductionResponse, TotalPath } from "@/Api/generated";

import { Model } from "resourcerer";

export interface NewEmissionsReductionResponse {
  baseline_emission: number;
  baseline_year: number;
  expected_path: TotalPath[];
  grid_decarb_path: TotalPath[];
  retrofit_budget_path: TotalPath[];
  retrofit_complete_path: TotalPath[];
  retrofit_in_progress_path: TotalPath[];
  total_mitigation_emission: number;
  unit: string;
}

export default class EmissionReductionModel extends Model<NewEmissionsReductionResponse> {
  url({ orgToken }: { orgToken: string }) {
    return `/api/path-to-net-zero-v2/${orgToken}/emission-reduction`;
  }

  static dependencies = ["orgToken", "partner_tokens", "asset_class_broad_categories"];
}

export class EmissionReductionOldModel extends Model<EmissionReductionResponse> {
  url() {
    return "/api/path-to-net-zero/emission-reduction";
  }

  static dependencies = ["organization_id", "partner_tokens", "asset_class_broad_categories"];
}
